var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tabs',{attrs:{"centered":"","color":"primary","next-icon":"fa-chevron-right","prev-icon":"fa-chevron-left","show-arrows":"","icons-and-text":_vm.$vuetify.breakpoint.smAndUp},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.id,attrs:{"href":tab.route}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$tc(tab.name, 2))}}):_vm._e(),_c('v-icon',{attrs:{"medium":_vm.$vuetify.breakpoint.smAndUp},domProps:{"textContent":_vm._s(tab.icon)}})],1)}),1),_c('i-card-list',{staticClass:"mx-3",attrs:{"api":"loginCarrousel","app":"settings.logincarrousel","headers":_vm.headers,"opt":{ mode: _vm.activeTab },"reload":_vm.reload,"title":_vm.$t('carrouselTips')},on:{"update:reload":function($event){_vm.reload=$event},"click:create":function($event){return _vm.changeRoute({ pk: 'create' })},"click:edit":function($event){return _vm.changeRoute($event)}},scopedSlots:_vm._u([{key:`item.title_color`,fn:function({ value }){return [_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(value)+" "),_c('span',{staticClass:"color-badges",style:({
            'background-color': value
          })})])]}},{key:`item.msg_color`,fn:function({ value }){return [_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(value)+" "),_c('span',{staticClass:"color-badges",style:({
            'background-color': value
          })})])]}},{key:`item.background_color`,fn:function({ value }){return [_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(value)+" "),_c('span',{staticClass:"color-badges",style:({
            'background-color': value
          })})])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }