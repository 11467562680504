<route>
{
  "meta": {
    "permission": [
      "settings.view_logincarrousel"
    ]
  }
}
</route>

<template>
  <div>
    <v-tabs
      centered
      color="primary"
      next-icon="fa-chevron-right"
      prev-icon="fa-chevron-left"
      show-arrows
      :icons-and-text="$vuetify.breakpoint.smAndUp"
      v-model="activeTab"
    >
      <v-tab v-for="tab in tabs" :key="tab.id" :href="tab.route">
        <span
          v-if="$vuetify.breakpoint.smAndUp"
          class="mt-2"
          v-text="$tc(tab.name, 2)"
        ></span>
        <v-icon :medium="$vuetify.breakpoint.smAndUp" v-text="tab.icon" />
      </v-tab>
    </v-tabs>
    <i-card-list
      api="loginCarrousel"
      app="settings.logincarrousel"
      class="mx-3"
      :headers="headers"
      :opt="{ mode: activeTab }"
      :reload.sync="reload"
      :title="$t('carrouselTips')"
      @click:create="changeRoute({ pk: 'create' })"
      @click:edit="changeRoute($event)"
    >
      <template v-slot:[`item.title_color`]="{ value }">
        <span class="primary--text">
          {{ value }}
          <span
            class="color-badges"
            :style="{
              'background-color': value
            }"
          ></span>
        </span>
      </template>
      <template v-slot:[`item.msg_color`]="{ value }">
        <span class="primary--text">
          {{ value }}
          <span
            class="color-badges"
            :style="{
              'background-color': value
            }"
          ></span>
        </span>
      </template>
      <template v-slot:[`item.background_color`]="{ value }">
        <span class="primary--text">
          {{ value }}
          <span
            class="color-badges"
            :style="{
              'background-color': value
            }"
          ></span>
        </span>
      </template>
    </i-card-list>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeTab: 'tips',
      tips: [],
      reload: false,
      tabs: [
        {
          id: 1,
          name: 'tip',
          icon: 'fa-lightbulb',
          route: '#tips'
        },
        {
          id: 2,
          name: 'banners',
          icon: 'far fa-images',
          route: '#banners'
        }
      ],
      loading: false
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('title'),
          value: 'title'
        },
        {
          text: this.$t('msg'),
          value: 'msg'
        },
        {
          text: this.$t('title_color'),
          value: 'title_color'
        },
        {
          text: this.$t('msg_color'),
          value: 'msg_color'
        },
        {
          text: this.$t('background_color'),
          value: 'background_color'
        }
      ]
    }
  },
  methods: {
    changeRoute(item) {
      this.$router.push({
        name: 'carrouselTips-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    }
  }
}
</script>
<style lang="sass">
.color-badges
  margin-left: .25rem
  border-radius: 100%
  border: 1px solid var(--v-primary-base)
  padding: 0px 9px
</style>
